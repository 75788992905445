@import "styles/variables";
@import "styles/mixins/breakpoints";

.grid {
  @include media-breakpoint-down(m) {
    --grid-columns: 8;
  }

  @include media-breakpoint-down(s) {
    --grid-columns: 4;
  }

  display: grid;
  grid-template-rows: repeat(var(--grid-rows, 1), 1fr);
  grid-template-columns: repeat(var(--grid-columns, #{$grid-columns}), 1fr);
  gap: var(--grid-gap, #{$space-unit * 2});

  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      --grid-gap: #{map-get($gutters, $breakpoint)};
    }
  }

  @include make-cssgrid();

  @include make-gap-classes();
}
