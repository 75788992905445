// генерируем классы для управление сеткой
@mixin make-cssgrid($columns: $grid-columns, $breakpoints: $grid-breakpoints) {
  @if $columns > 0 {
    @for $i from 1 through $columns {
      .g-col-#{$i} {
        grid-column: auto / span $i;
      }
    }

    // Start with `1` because `0` is and invalid value.
    // Ends with `$columns - 1` because offsetting by the width of an entire row isn't possible.
    @for $i from 1 through ($columns - 1) {
      .g-start-#{$i} {
        grid-column-start: $i !important;
      }
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      @if $columns > 0 {
        @for $i from 1 through $columns {
          .g-col-#{$breakpoint}-#{$i} {
            grid-column: auto / span $i;
          }
        }

        // Start with `1` because `0` is and invalid value.
        // Ends with `$columns - 1` because offsetting by the width of an entire row isn't possible.
        @for $i from 1 through ($columns - 1) {
          .g-start-#{$breakpoint}-#{$i} {
            grid-column-start: $i !important;
          }
        }
      }
    }
  }
}

// Формируем классы для контроля
@mixin make-gap-classes($breakpoints: $grid-breakpoints) {
  @each $spacersKey, $spacersValue in $spacers {
    &.g-gap-#{$spacersKey} {
      --grid-gap: #{$spacersValue}px !important;
    }

    @each $breakpoint in map-keys($breakpoints) {
      $infix: breakpoint-infix($breakpoint, $breakpoints);

      @include media-breakpoint-up($breakpoint, $breakpoints) {
        &.g-gap-#{$infix}-#{$spacersKey} {
          --grid-gap: #{$spacersValue}px !important;
        }
      }
    }
  }
}
