@import "root";
@import "variables";

@import "mixins/breakpoints";
@import "mixins/grid";
@import "mixins/spacers";

@import "components/normalize";
@import "components/grid";
@import "components/spacers";
@import "components/carousel";
@import "fonts/fonts.css";

.shadowLevel1 {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.shadowLevel2 {
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.15), 0 8px 16px rgba(0, 0, 0, 0.04);
}

.shadowDay {
  box-shadow: var(--shadow-day)
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  overflow: hidden;

  white-space: nowrap;

  clip: rect(0 0 0 0);
  clip-path: inset(50%);
}

.text-center {
  text-align: center;
}

.pre-wrap {
  white-space: pre-wrap;
}

.intercom_launcher {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  background: linear-gradient(
    285.27deg,
    #f0b60a 5.55%,
    #f09d9d 50.3%,
    #89a3ff 91.15%
  );
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  color: white !important;
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 174, 255, 0.04) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(0, 174, 255, 0.03) 100%
  );
}

input[data-com-onepassword-filled="light"],
input[data-com-onepassword-filled="dark"],
select[data-com-onepassword-filled="dark"],
textarea[data-com-onepassword-filled="dark"] {
  background-color: transparent !important;
}
