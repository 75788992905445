:root {

  --blue-palettes-100: #4b8bda;
  --blue-palettes-90: #69a6f1;
  --blue-palettes-80: #87b8f4;
  --blue-palettes-70: #79b5ff;
  --blue-palettes-40: #99affb;
  --blue-palettes-10: #edf3fd;

  --dark-blue-palettes-100: #091E38;
  --dark-blue-palettes-95: #091e3a;
  --dark-blue-palettes-90: #5C9CEB;
  --dark-blue-palettes-80: #77B4FF;
  --dark-blue-palettes-70: #9BC8FF;
  --dark-blue-palettes-10: #191F2C;
  --dark-blue-palettes-white: #0D0D0D;

  --black-60: #8C9AAB;

  --gray-palettes-100: #2c2e2b;
  --gray-palettes-90: #7c8a9e;
  --gray-palettes-70: #d6d6d6;
  --gray-palettes-60: #dfe5ee;
  --gray-palettes-40: #f3f3f3;
  --gray-palettes-30: #eaeef4;
  --gray-palettes-20: #e0e6f0;
  --gray-palettes-10: #eeeeee;
  --gray-palettes-5: #fcfcfc;

  --red-palettes-100: #ff0000;
  --red-palettes-90: #ff4646;
  --red-palettes-80: #f45959;
  --red-palettes-20: #ffd1ce;
  --red-palettes-10: #ffe3e3;

  --orange-palettes-100: #ff5c22;
  --orange-palettes-10: #fff1e5;

  --white-palettes-100: #ffffff;

  --green-palettes-80: #6ac53f;


  --input-default-background-color: var(--blue-palettes-10);
  --input-disabled-background-color: var(--gray-palettes-10);
  --input-error-background-color: var(--red-palettes-10);
  --input-default-placeholder-color: var(--gray-palettes-90);
  --input-disabled-placeholder-color: var(--gray-palettes-70);
  --input-value-color: var(--gray-palettes-100);
  --input-error-text-color: var(--red-palettes-100);
  --input-button-border-disabled: var(--gray-palettes-70);
  --input-label-color: var(--text-base);
  --input-disabled-label-color: var(--gray-palettes-90);
  --input-required-symbol-color: var(--red-palettes-100);
  --input-lenght-conunter-color: var(--gray-palettes-90);

  --input-default-box-shadow: none;
  --input-disabled-box-shadow: none;
  --input-focus-box-shadow: 0px 0px 0px 2px var(--blue-palettes-100);
  --input-hover-box-shadow: 0px 0px 0px 1px var(--gray-palettes-90);

  --input-default-border: none;
  --input-disabled-border: none;
  --input-focus-border: none;
  --input-hover-border: none;

  --input-height: 60px;
  --input-padding: 5px 5px 5px 20px;
  --input-padding-with-right-element: 50px;
  --input-padding-with-left-element: 60px;
  --input-padding-right-text: 5px 20px 5px 5px;
  --input-padding-with-icon: 50px;
  --input-padding-with-icon-and-hint: 84px;
  --input-padding-with-password-eye: 0 20px 0 4px;
  --input-padding-with-password-eye-and-hint: 0 55px 0 4px;
  --input-padding-right-element-wrapper: 0 0 0 20px;
  --input-padding-left-element-wrapper: 0 20px 0 4px;
  --input-help-text-margin-top: 8px;
  --input-small-height: 46px;
  --input-font-size: 16px;
  --input-small-font-size: 14px;
  --input-small-placeholder-padding-right: 16px;
  --input-line-height: 25px;
  --input-letter-spacing: 0.02em;
  --input-placeholder-font-size: 16px;
  --input-small-placeholder-font-size: 14px;
  --input-placeholder-line-height: 24px;
  --input-border-radius: 6px;
  --input-label-text-margin-bottom: 8px;
  --input-label-required-symbol-margin-left: 4px;
  --input-error-message-text-left: 16px;
  --input-error-message-text-font-size: 13px;
  --input-error-message-text-line-height: 24px;
  --input-small-error-message-text-width: 195px;
  --input-lenght-conunter-font-size: 12px;
  --input-lenght-conunter-line-height: 16px;
  --input-text-area-min-width: 255px;
  --input-text-area-min-height: 166px;
  --input-text-area-max-height: 166px;

  --button-text-primary-color: var(--white-palettes-100);
  --button-default-primary-background-color: var(--blue-palettes-90);
  --button-hover-primary-background-color: var(--blue-palettes-80);
  --button-default-primary-border-color: rgba(255, 255, 255, 0);
  --button-active-primary-background-color: var(--blue-palettes-100);
  --button-active-primary-border-color: var(--blue-palettes-100);
  --button-disabled-primary-color: var(--gray-palettes-70);
  --button-disabled-primary-background-color: var(--gray-palettes-10);

  --button-default-secondary-text-color: var(--blue-palettes-90);
  --button-default-secondary-border-color: var(--blue-palettes-90);
  --button-default-secondary-background-color: none;
  --button-hover-secondary-text-color: var(--blue-palettes-80);
  --button-hover-secondary-border-color: var(--blue-palettes-80);
  --button-hover-secondary-background-color: none;
  --button-active-secondary-border-color: var(--blue-palettes-100);
  --button-active-secondary-text-color: var(--blue-palettes-100);
  --button-active-secondary-background-color: none;
  --button-disabled-secondary-color: var(--gray-palettes-70);
  --button-disabled-secondary-background-color: none;

  --button-default-ghost-background-color: none;
  --button-default-ghost-text-color: var(--blue-palettes-90);
  --button-hover-ghost-text-color: var(--blue-palettes-80);
  --button-active-ghost-text-color: var(--blue-palettes-100);
  --button-active-ghost-border-color: var(--blue-palettes-100);
  --button-disabled-ghost-background-color: none;
  --button-disabled-ghost-text-color: var(--gray-palettes-70);

  --button-text-danger-color: var(--white-palettes-100);
  --button-default-danger-background-color: var(--red-palettes-80);
  --button-default-danger-border-color: var(--red-palettes-80);
  --button-disabled-danger-color: var(--gray-palettes-70);
  --button-hover-danger-opacity: 0.8;

  --button-min-width-small: 80px;
  --button-height-small: 28px;
  --button-padding-large: 16px 14px;
  --button-padding-small: 4px 16px;

  --button-font-size-small: 12px;

  --button-hover-color: white;
  --button-active-color: white;
  --button-default-background-color: var(--dark-blue-palettes-100);
  --button-hover-background-color: var(--dark-blue-palettes-95);
  --button-active-background-color: var(--dark-blue-palettes-100);
  --button-border-color: transparent;
  --button-active-border-color: transparent;
  --button-color: white;
  --button-disabled-color: white;
  --button-disabled-background-color: var(--gray-palettes-10);
  --button-focus-outline-color: transparent;
  --button-min-width: 224px;
  --button-border-radius: 10px;
  --button-border-width: 0px;
  --button-font-weight: 400;

  --button-height-medium: 48px;
  --button-font-size-medium: 20px;
  --button-min-width-medium: 224px;

  --button-min-width-large: 350px;
  --button-height-large: 64px;
  --button-font-size-large: 20px;

  @media (max-width: 768px) {
    --button-min-width-large: 224px;
    --button-height-large: 48px;
  }

  --button-default-opacity: 1;
  --button-hover-opacity: 0.7;
  --button-active-opacity: 0.5;

  --button-padding-medium: 12px 32px;

  --typography-h1-font-size: 52px;
  --typography-h1-line-height: 64px;
  --typography-h1-font-weight: 500;
  --typography-h1-letter-spacing: 0;
  --typography-h1-color: var(--gray-palettes-100);

  @media (max-width: 768px) {
    --typography-h1-font-size: 36px;
    --typography-h1-line-height: 40px;
  }

  --typography-h2-font-size: 36px;
  --typography-h2-line-height: 40px;
  --typography-h2-font-weight: 500;
  --typography-h2-letter-spacing: 0;
  --typography-h2-color: var(--gray-palettes-100);

  @media (max-width: 768px) {
    --typography-h2-font-size: 36px;
    --typography-h2-line-height: 40px;
  }

  --typography-body-m-font-size: 24px;
  --typography-body-m-line-height: 40px;
  --typography-body-m-letter-spacing: 0;
  --typography-body-m-color: var(--gray-palettes-100);

  @media (max-width: 768px) {
    --typography-body-m-font-size: 16px;
    --typography-body-m-line-height: 24px;
  }

  --typography-caption1-font-size: 18px;
  --typography-caption1-line-height: 30px;
  --typography-caption1-font-weight: 400;
  --typography-caption1-letter-spacing: 0;

  @media (max-width: 768px) {
    --typography-caption1-font-size: 12px;
    --typography-caption1-line-height: 20px;
  }

  --typography-subtitle1-font-size: 20px;
  --typography-subtitle1-line-height: 34px;
  --typography-subtitle1-font-weight: 500;
  --typography-subtitle1-letter-spacing: 0;

  --typography-subtitle2-font-size: 16px;
  --typography-subtitle2-line-height: 24px;
  --typography-subtitle2-font-weight: 500;
  --typography-subtitle2-letter-spacing: 0;
  --typography-subtitle2-color: var(--gray-palettes-100);


  --typography-button-s-font-size: 14px;
  --typography-button-s-line-height: 22px;
  --typography-button-font-weight: 500;
  --typography-button-s-color: var(--white-palettes-100);

  --aliases-link: var(--gray-palettes-100);

  --shadow-day: 0 4px 16px 0 rgba(9, 30, 56, 0.06);
  --shadow-day-hover: 0 8px 16px rgba(0, 0, 0, 0.08);

}
